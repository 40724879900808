.page
	font-family $roboto
	background-color #b10f3b
	background-image url(../images/body-bg.png)
	background-repeat repeat

	&__content
		position relative
		width $site-width
		margin 58px auto 0
		padding-top 314px
		background-color #fff
		+below($site-width)
			width 100%
			min-width 990px

		&:before
			position absolute
			content ""
			width $site-width+44
			top -22px
			right -22px
			bottom -22px
			left - 22px
			background-color rgba(255,255,255,.2)
			border-radius 20px
			z-index -1
			+below($site-width-full)
				display none

[placeholder]::placeholder
	color #7a7a7a
