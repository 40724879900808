.doctor-review
	position relative
	min-height 424px
	padding-top 38px
	padding-bottom 100px
	font-size 18px
	font-weight 300
	line-height 22px
	color #000
	background-color #f5fbfe
	border 1px solid #ddf2fc

	&_left
		padding-right 27px
		padding-left 228px
		background-image url(../images/doctor1.png)
		background-repeat no-repeat
		background-position top left
		border-left none
		border-radius 0 16px 16px 0

	&_right
		padding-right 229px
		padding-left 27px
		background-image url(../images/doctor2.png)
		background-repeat no-repeat
		background-position top right
		border-right none
		border-radius 16px 0 0 16px


	&__title
		position absolute
		width 268px
		bottom 46px
		font-style italic

		&_left
			right 27px

		&_right
			left 27px
