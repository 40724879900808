.s-efficiency
	padding-top 66px
	padding-bottom 53px
	background-image url(../images/s-efficiency-bg.jpg)
	background-repeat no-repeat
	background-size cover

	&__title
		margin-bottom 48px
		font-size 35px
		font-weight 500
		line-height 37px
		text-align center
		color #0d4974

	&__content
		font-size 18px
		font-weight 300
		line-height 22px
		color #000

		&-list
			margin-left 58px

		p
			margin-bottom 23px

			span
				font-weight 500

		h3
			margin-bottom 10px
			font-weight 500
