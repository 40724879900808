.timeout

	&__label
		display inline-block
		vertical-align middle
		margin-right 30px
		font-size 20px
		font-weight 300
		line-height 22px
		color #000

		&_short
			display block
			margin-right 0
			margin-bottom 13px
			font-size 26px
			line-height 28px
			text-align center

			span
				display block
				font-size 35px
				line-height 37px

		span
			font-weight 500
			color $pink
			text-transform uppercase

	&__items
		display inline-block
		vertical-align middle

	&__item
		display inline-block
		width 74px
		height 74px
		margin-right 10px
		background-color #ebf5b2
		border-radius 50%

		&:last-child
			margin-right 0

		&-count
			font-size 36px
			line-height 36px
			font-weight 500
			text-align center
			color #405408
			transform translateY(20px)
