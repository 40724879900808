.button
	display inline-block
	overflow hidden
	font-family $lobster
	text-decoration none
	border none
	transition 0.2s ease-out 0s
	user-select none
	box-shadow none
	cursor pointer

	&_common
		position relative
		padding 18px 31px
		font-size 34px
		line-height 34px
		color #fff
		background-color $pink
		border-radius 10px
		backface-visibility hidden
		will-change transform
		//transform translateY(0)
		transform scale(1)
		box-shadow 0 6px #c00b36
		//transition 0s
		transition .2s transform ease-out 0s

		/*&:hover
			transform translateY(2px)
			box-shadow 0 4px #c00b36*/

		&:hover
			transform scale(1.1)

		&:active
			transform translateY(2px) scale(1.1)
			box-shadow 0 4px #c00b36

		/*&:active
			transform translateY(6px)
			box-shadow 0 0 #c00b36*/

		&_short
			padding 17px 18px
