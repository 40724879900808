.s-expert
	padding-top 78px
	padding-bottom 10px
	background-image url(../images/s-expert-bg.png)
	background-repeat no-repeat
	background-position top left

	&__content
		padding-left 420px
		font-size 18px
		font-weight 300
		line-height 24px
		color #0d4974

		&-painting
			float right
			sprite $painting

		p
			margin-bottom 28px
			font-style italic

			&:last-child
				margin-bottom 0

			span
				font-weight 500

	&__title
		margin-bottom 40px
		font-size 36px
		font-weight 500
		line-height 38px

	&__approvals
		margin-top 95px
		text-align center

	&__approval
		display inline-block
		vertical-align top
		margin-right 26px
		text-align left

		&:last-child
			margin-right 0
