.logo
	position relative
	width 298px
	height 130px
	padding-top 20px
	background-color #f0174a
	text-align center
	color #fff
	border 5px solid #fff
	border-top none

	&:before
		position absolute
		content ""
		left -5px
		bottom -54px
		border-style solid
		border-color #fff transparent transparent transparent
		border-width 49px 149px 0

	&:after
		position absolute
		content ""
		left 0
		bottom -48px
		border-style solid
		border-color #f0174a transparent transparent transparent
		border-width 48px 144px 0

	&_green
		background-color #96c55a

		&:after
			border-color #96c55a transparent transparent transparent

	&_dark-green
		background-color #576347

		&:before
			border-color #828b77 transparent transparent transparent

		&:after
			border-color #576347 transparent transparent transparent

	&_small
		width 230px
		height 100px
		border none

		&:before
			display none

		&:after
			bottom -41px
			border-width 41px 115px 0

	&_short
		width 288px
		height 74px
		padding-top 15px
		border-color #828b77

		&:before
			bottom -54px
			border-width 49px 144px 0

		&:after
			border-width 48px 139px 0

	&__title
		margin-bottom 8px
		font-family $roboto
		font-size 43px
		line-height 46px
		font-weight 500

		&:before
			display inline-block
			content ""
			margin-right 12px
			retinaSprite $logo_group

		&_small
			margin-bottom 6px
			font-size 35px
			line-height 38px

			&:before
				retinaSprite $logo_small_group

		&_short
			margin-bottom 0
			font-size 43px
			line-height 45px
			color #828b77

			&:before
				opacity .26
				retinaSprite $logo_middle_group

	&__subtitle
		font-family $roboto
		font-size 25px
		line-height 26px
		font-weight 300

		&_small
			font-size 20px
			line-height 21px

		&_short
			display none

