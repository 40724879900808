.features
	display block

.feature
	position relative
	padding 35px 15px 35px 50px

	.features__item:nth-child(even) &
		background-color #ebf5b2

	&__count
		position absolute
		width 63px
		height 63px
		top 26px
		left -30px
		font-size 40px
		font-weight 700
		line-height 59px
		text-align center
		color #71ad2a
		background-color #ebf5b2
		border 3px solid #fff
		border-radius 50%

	&__title
		margin-bottom 16px
		font-size 24px
		font-weight 500
		line-height 29px
		color #71ad2a

	&__description
		font-size 18px
		font-weight 300
		line-height 22px
		color #000

		p
			margin-bottom 22px

			span
				font-weight 500

		li
			position relative
			margin-bottom 5px
			padding-left 30px

			&:before
				position absolute
				content ""
				top 5px
				left 0
				retinaSprite $check_group

	&__subitems
		margin-top 34px

		&_cols
			display table
			width 100%

		&-col
			display table-cell
			vertical-align top
			padding 0 10px 49px

			&_left
				padding-right 68px

			&_image

				&:before
					display block
					content ""
					margin-bottom 28px
					sprite $package_small

			&-title
				margin 10px 0 26px -65px
				font-size 20px
				font-weight 500
				line-height 29px
				color #c0c0c0
				text-transform uppercase

.feature-subitem
	position relative
	min-height 95px
	margin-bottom 49px
	padding-left 45px

	&:last-child
		margin-bottom 0

	&__title
		margin-bottom 8px
		font-family $lobster
		font-size 30px
		line-height 30px
		color #66a718

	&__image-wrapper
		position absolute
		overflow hidden
		width 95px
		height 95px
		top 0
		left -72px
		border-radius 50%

	&__image
		display block
		width 100%
		border-radius 50%

	&__description
		font-size 18px
		font-weight 300
		line-height 22px
		color #000

