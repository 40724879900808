.s-audio-reviews
	padding-top 56px
	padding-bottom 44px

	&__title
		margin-bottom 10px
		font-size 36px
		font-weight 500
		line-height 38px
		text-align center
		color #000

	&__subtitle
		font-size 22px
		font-weight 300
		line-height 39px
		text-align center
		color #000

	&__items
		margin-top 50px
		text-align center

	&__item
		display inline-block
		margin-right 30px
		+below($site-width)
			margin-right 15px

		&:last-child
			margin-right 0

		&-title
			margin-bottom 27px
			font-size 25px
			font-weight 300
			line-height 27px
			text-align center
			color #000
