.approval
	position relative
	width 267px
	padding 14px 10px 14px 88px
	font-size 18px
	line-height 22px
	font-weight 500
	color #8d9d4a
	text-transform uppercase
	background-color rgba(235, 245, 178, .37)
	border-radius 6px

	&_blue
		padding 13px 10px 13px 88px
		color #0d4974
		background-color #eaf8ff

	&:before
		position absolute
		content ""
		top 0
		bottom 0
		left 18px
		margin auto

	&_rst

		&:before
			retinaSprite $rst_group

	&_ramn

		&:before
			retinaSprite $ramn_group


	&_rst-blue

		&:before
			retinaSprite $rst_blue_group

	&_ramn-blue

		&:before
			retinaSprite $ramn_blue_group

	&_natural-blue
		padding-left 76px

		&:before
			retinaSprite $natural_blue_group