.s-order
	position relative

	&__inner
		padding-top 137px
		padding-bottom 53px

	&__price
		position absolute
		top 0
		right 147px
		font-family $lobster

		&-label
			display inline-block
			vertical-align bottom
			margin-right 20px
			font-size 40px
			line-height 46px
			color #000

		&-value-wrapper
			position relative
			display inline-block
			vertical-align bottom

		&-value
			margin-right 3px
			font-size 56px
			line-height 56px
			color $pink

		&-currency
			font-size 38px
			color $pink

		&-old
			position absolute
			top -8px
			right 0
			font-size 26px
			line-height 26px
			color #a8a8a8

			&:before
				position absolute
				content ""
				width 120%
				height 2px
				top 0
				bottom 0
				left -10%
				margin auto
				background-color $pink
				transform rotate(-18deg)

		&-more
			position absolute
			font-family $roboto
			font-size 17px
			font-weight 300
			line-height 19px
			color #a8a8a8

	&__form

		&-row
			text-align center

		&-input-wrapper
			display inline-block
			vertical-align top
			width 271px
			margin 0 15px

		&-controlls
			margin-top 44px
			padding-right 28px
			text-align right
			+below($site-width)
				text-align center

	&__timeout
		display inline-block
		vertical-align top
		margin-right 50px
		+below($site-width)
			display block
			margin-right 0
			margin-bottom 30px
			text-align center
