.footer
	position relative
	min-height 155px
	color #828b77
	background-color #576347
	border-top 10px solid #fff
	+below($site-width)
		width 100%
		min-width 990px

	&__logo
		display inline-block
		vertical-align middle
		margin-right 45px

	&__title
		display inline-block
		vertical-align middle
		margin-top 40px
		font-size 25px
		font-weight 300
		line-height 26px
