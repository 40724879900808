.s-notices
	padding-top 47px
	padding-bottom 52px

	&__items
		text-align center

	&__item
		display inline-block
		vertical-align top
		width 271px
		margin-right 20px
		text-align left

		&:last-child
			margin-right 0

		&_middle
			margin-top 50px