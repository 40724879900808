.header
	position relative
	height 497px
	z-index 1
	+below($site-width)
		min-width 990px

	&__bg
		position absolute
		display block
		width 100%
		height 929px
		background-image url(../images/header-bg-text.png)
		background-repeat no-repeat
		background-size auto 100%
		background-position center
		top 0
		left 0

	&__logo
		position absolute
		width 298px
		top 0
		right 0
		left 0
		margin auto

	&__approvals
		position absolute
		top 46px
		right 76px
		text-align left

	&__approval
		margin-bottom 4px

		&:last-child
			margin-bottom 0

	&__effects-list
		position absolute
		width 443px
		top 240px
		right 400px
		+between($site-width-full+1, 1340px)
			right 42%
		+at('l')
			right 45%
		+below($site-width)
			right 49%

	&__package
		position absolute
		top 235px
		right -116px
		+between($site-width-full+1, 1340px)
			right -22px
		+below($site-width-full)
			right 0