.s-reviews
	padding-top 44px
	padding-bottom 43px

	&__title
		margin-bottom 45px
		font-size 36px
		font-weight 500
		line-height 38px
		text-align center
		color #000
