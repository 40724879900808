.reviews

	&__wrapper
		overflow hidden
		margin 0 auto

	&__items
		display flex
		-webkit-box-lines multiple
		flex-wrap wrap
		+below($site-width - 1)
			flex-wrap nowrap

	&__item-wrapper
		position relative
		display flex
		vertical-align top
		flex-shrink 0

	&__item
		position relative
		min-height 320px
		margin 0 11px 11px
		padding 36px 36px 36px 264px
		background-color #f5faec
		border-radius 20px

		.reviews__item-wrapper:nth-child(odd) &
			padding 36px 264px 36px 36px

		&-consumer
			position absolute
			width 192px
			top 26px
			left 32px

			.reviews__item-wrapper:nth-child(odd) &
				left auto
				right 32px

		&-image-wrapper
			overflow hidden
			width 184px
			height 184px
			margin 0 auto 23px
			border-radius 50%

		&-image
			display block
			max-width 100%
			border-radius 50%

		&-consumer-info
			padding 3px 0
			font-size 18px
			font-weight 300
			line-height 22px
			text-align center
			color #000
			background-color #fff
			border-radius 8px

		&-content
			font-size 18px
			font-weight 300
			font-style italic
			line-height 22px
			color #000

			p
				margin-bottom 24px

		&-title
			margin-bottom 20px
			font-size 24px
			font-weight 500
			font-style italic
			line-height 26px
			color #000

	&__bullets
		margin-top 25px
		text-align center

	&__bullet
		display inline-block
		vertical-align top
		width 24px
		height 24px
		margin 0 3px
		background-color #e2eac4
		border-radius 50%
		transition .2s background-color ease-out 0s
		cursor pointer

		&:hover
		&_state_active
			background-color #a0c223

