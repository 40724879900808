/*
Stylus variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon_home_width;
}

The large array-like variables contain all information about a single icon
$icon_home = x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet = width height image $spritesheet_sprites;
*/
$package_middle_name = 'package-middle';
$package_middle_x = 491px;
$package_middle_y = 0px;
$package_middle_offset_x = -491px;
$package_middle_offset_y = 0px;
$package_middle_width = 308px;
$package_middle_height = 445px;
$package_middle_total_width = 1103px;
$package_middle_total_height = 615px;
$package_middle_image = '../images/sprites/base.png';
$package_middle = 491px 0px -491px 0px 308px 445px 1103px 615px '../images/sprites/base.png' 'package-middle';
$package_small_name = 'package-small';
$package_small_x = 807px;
$package_small_y = 0px;
$package_small_offset_x = -807px;
$package_small_offset_y = 0px;
$package_small_width = 296px;
$package_small_height = 344px;
$package_small_total_width = 1103px;
$package_small_total_height = 615px;
$package_small_image = '../images/sprites/base.png';
$package_small = 807px 0px -807px 0px 296px 344px 1103px 615px '../images/sprites/base.png' 'package-small';
$package_name = 'package';
$package_x = 0px;
$package_y = 0px;
$package_offset_x = 0px;
$package_offset_y = 0px;
$package_width = 483px;
$package_height = 615px;
$package_total_width = 1103px;
$package_total_height = 615px;
$package_image = '../images/sprites/base.png';
$package = 0px 0px 0px 0px 483px 615px 1103px 615px '../images/sprites/base.png' 'package';
$painting_name = 'painting';
$painting_x = 807px;
$painting_y = 352px;
$painting_offset_x = -807px;
$painting_offset_y = -352px;
$painting_width = 134px;
$painting_height = 93px;
$painting_total_width = 1103px;
$painting_total_height = 615px;
$painting_image = '../images/sprites/base.png';
$painting = 807px 352px -807px -352px 134px 93px 1103px 615px '../images/sprites/base.png' 'painting';
$sheet1_name = 'sheet1';
$sheet1_x = 807px;
$sheet1_y = 453px;
$sheet1_offset_x = -807px;
$sheet1_offset_y = -453px;
$sheet1_width = 116px;
$sheet1_height = 99px;
$sheet1_total_width = 1103px;
$sheet1_total_height = 615px;
$sheet1_image = '../images/sprites/base.png';
$sheet1 = 807px 453px -807px -453px 116px 99px 1103px 615px '../images/sprites/base.png' 'sheet1';
$sheet2_name = 'sheet2';
$sheet2_x = 491px;
$sheet2_y = 453px;
$sheet2_offset_x = -491px;
$sheet2_offset_y = -453px;
$sheet2_width = 58px;
$sheet2_height = 113px;
$sheet2_total_width = 1103px;
$sheet2_total_height = 615px;
$sheet2_image = '../images/sprites/base.png';
$sheet2 = 491px 453px -491px -453px 58px 113px 1103px 615px '../images/sprites/base.png' 'sheet2';
$sp_base_width = 1103px;
$sp_base_height = 615px;
$sp_base_image = '../images/sprites/base.png';
$sp_base_sprites = $package_middle $package_small $package $painting $sheet1 $sheet2;
$sp_base = 1103px 615px '../images/sprites/base.png' $sp_base_sprites;

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.

// The list formatted variables are intended for mixins like `retinaSprite` and `retinaSprites`.


/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  spriteWidth($icon_home)
}

.icon-email {
  sprite($icon_email)
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
spriteWidth($sprite) {
  width: $sprite[4];
}

spriteHeight($sprite) {
  height: $sprite[5];
}

spritePosition($sprite) {
  background-position: $sprite[2] $sprite[3];
}

spriteImage($sprite) {
  background-image: url($sprite[8]);
}

sprite($sprite) {
  spriteImage($sprite)
  spritePosition($sprite)
  spriteWidth($sprite)
  spriteHeight($sprite)
}


// The `retinaSprite` mixin sets up rules and a media query for a sprite/retina sprite.
// It should be used with a "retina group" variable.
//
// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
//
// $icon_home_group = 'icon-home' $icon_home $icon_home_2x;
//
// .icon-home {
//     retinaSprite($icon_home_group)
// }


spriteBackgroundSize($sprite) {
	background-size $sprite[6] $sprite[7]
}

retinaSprite($retina_group) {
	$normal_sprite = $retina_group[1]
	$retina_sprite = $retina_group[2]
	sprite($normal_sprite)

	@media (-webkit-min-device-pixel-ratio: 2),
				 (min-resolution: 192dpi) {
		spriteImage($retina_sprite)
		spriteBackgroundSize($normal_sprite)
	}
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of Stylus

This must be run when you have at least 2 sprites.
  If run with a single sprite, then there will be reference errors.

sprites($spritesheet_sprites);
*/
sprites($sprites) {
  for $sprite in $sprites {
    $sprite_name = $sprite[9];
    .{$sprite_name} {
      sprite($sprite);
    }
  }
}

// The `retinaSprites` mixin generates a CSS rule and media query for retina groups
// This yields the same output as CSS retina template but can be overridden in Stylus
//
// retinaSprites($retina_groups)

retinaSprites($retina_groups) {
	for $retina_group in $retina_groups {
		$sprite_name = $retina_group[0];
		.{$sprite_name} {
			retinaSprite $retina_group
		}
	}
}
