.select
	width 100%
	height 41px
	padding 4px 18px
	font-family $roboto
	font-size 20px
	font-weight 300
	color #7a7a7a
	background-color #eeeeee
	background-image url(../images/select-arrow.png)
	background-position 95% 50%
	background-repeat no-repeat
	border 1px solid #cecece
	border-radius 10px
	appearance none

	&:focus
		background-color #fefefe
