.input
	width 100%
	height 41px
	padding 4px 18px
	font-family $roboto
	font-size 20px
	font-weight 300
	color #7a7a7a
	background #eeeeee
	border 1px solid #cecece
	border-radius 10px

	&:focus
		background #fefefe
