.effects-list
	text-align right

	&__item
		display inline-block
		margin-bottom 3px
		padding 14px 14px
		font-size 20px
		line-height 24px
		font-weight 500
		color #fff
		background-color rgba(0,0,0, .75)
		border-radius 8px

		&:last-child
			margin-bottom 0
