.notice
	padding 38px 34px 58px
	border-radius 16px
	background-color #f4fad6

	&_middle
		background-color #f7ebef

	&__title
		margin-bottom 24px
		font-size 20px
		font-weight 500
		line-height 22px
		color #000

	&__text
		font-size 18px
		font-weight 300
		line-height 22px
		color #000

		p
			margin-bottom 24px

			&:last-child
				margin-bottom 0
