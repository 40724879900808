.s-features
	padding-top 75px
	padding-bottom 62px

	&__title
		margin-bottom 58px
		font-size 35px
		font-weight 500
		line-height 38px
		text-align center
		color #293604

	&__items
		padding-left 30px
