/*
Stylus variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon_home_width;
}

The large array-like variables contain all information about a single icon
$icon_home = x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet = width height image $spritesheet_sprites;
*/
$check_name = 'check';
$check_x = 92px;
$check_y = 136px;
$check_offset_x = -92px;
$check_offset_y = -136px;
$check_width = 16px;
$check_height = 14px;
$check_total_width = 222px;
$check_total_height = 206px;
$check_image = '../images/sprites/retina.png';
$check = 92px 136px -92px -136px 16px 14px 222px 206px '../images/sprites/retina.png' 'check';
$infos_area_name = 'infos-area';
$infos_area_x = 88px;
$infos_area_y = 0px;
$infos_area_offset_x = -88px;
$infos_area_offset_y = 0px;
$infos_area_width = 76px;
$infos_area_height = 62px;
$infos_area_total_width = 222px;
$infos_area_total_height = 206px;
$infos_area_image = '../images/sprites/retina.png';
$infos_area = 88px 0px -88px 0px 76px 62px 222px 206px '../images/sprites/retina.png' 'infos-area';
$infos_composition_name = 'infos-composition';
$infos_composition_x = 0px;
$infos_composition_y = 82px;
$infos_composition_offset_x = 0px;
$infos_composition_offset_y = -82px;
$infos_composition_width = 52px;
$infos_composition_height = 74px;
$infos_composition_total_width = 222px;
$infos_composition_total_height = 206px;
$infos_composition_image = '../images/sprites/retina.png';
$infos_composition = 0px 82px 0px -82px 52px 74px 222px 206px '../images/sprites/retina.png' 'infos-composition';
$infos_form_name = 'infos-form';
$infos_form_x = 0px;
$infos_form_y = 0px;
$infos_form_offset_x = 0px;
$infos_form_offset_y = 0px;
$infos_form_width = 80px;
$infos_form_height = 74px;
$infos_form_total_width = 222px;
$infos_form_total_height = 206px;
$infos_form_image = '../images/sprites/retina.png';
$infos_form = 0px 0px 0px 0px 80px 74px 222px 206px '../images/sprites/retina.png' 'infos-form';
$logo_middle_name = 'logo-middle';
$logo_middle_x = 172px;
$logo_middle_y = 54px;
$logo_middle_offset_x = -172px;
$logo_middle_offset_y = -54px;
$logo_middle_width = 46px;
$logo_middle_height = 46px;
$logo_middle_total_width = 222px;
$logo_middle_total_height = 206px;
$logo_middle_image = '../images/sprites/retina.png';
$logo_middle = 172px 54px -172px -54px 46px 46px 222px 206px '../images/sprites/retina.png' 'logo-middle';
$logo_small_name = 'logo-small';
$logo_small_x = 106px;
$logo_small_y = 164px;
$logo_small_offset_x = -106px;
$logo_small_offset_y = -164px;
$logo_small_width = 36px;
$logo_small_height = 38px;
$logo_small_total_width = 222px;
$logo_small_total_height = 206px;
$logo_small_image = '../images/sprites/retina.png';
$logo_small = 106px 164px -106px -164px 36px 38px 222px 206px '../images/sprites/retina.png' 'logo-small';
$logo_name = 'logo';
$logo_x = 118px;
$logo_y = 82px;
$logo_offset_x = -118px;
$logo_offset_y = -82px;
$logo_width = 44px;
$logo_height = 46px;
$logo_total_width = 222px;
$logo_total_height = 206px;
$logo_image = '../images/sprites/retina.png';
$logo = 118px 82px -118px -82px 44px 46px 222px 206px '../images/sprites/retina.png' 'logo';
$natural_blue_name = 'natural-blue';
$natural_blue_x = 58px;
$natural_blue_y = 164px;
$natural_blue_offset_x = -58px;
$natural_blue_offset_y = -164px;
$natural_blue_width = 40px;
$natural_blue_height = 42px;
$natural_blue_total_width = 222px;
$natural_blue_total_height = 206px;
$natural_blue_image = '../images/sprites/retina.png';
$natural_blue = 58px 164px -58px -164px 40px 42px 222px 206px '../images/sprites/retina.png' 'natural-blue';
$ramn_blue_name = 'ramn-blue';
$ramn_blue_x = 172px;
$ramn_blue_y = 0px;
$ramn_blue_offset_x = -172px;
$ramn_blue_offset_y = 0px;
$ramn_blue_width = 50px;
$ramn_blue_height = 46px;
$ramn_blue_total_width = 222px;
$ramn_blue_total_height = 206px;
$ramn_blue_image = '../images/sprites/retina.png';
$ramn_blue = 172px 0px -172px 0px 50px 46px 222px 206px '../images/sprites/retina.png' 'ramn-blue';
$ramn_name = 'ramn';
$ramn_x = 60px;
$ramn_y = 82px;
$ramn_offset_x = -60px;
$ramn_offset_y = -82px;
$ramn_width = 50px;
$ramn_height = 46px;
$ramn_total_width = 222px;
$ramn_total_height = 206px;
$ramn_image = '../images/sprites/retina.png';
$ramn = 60px 82px -60px -82px 50px 46px 222px 206px '../images/sprites/retina.png' 'ramn';
$rst_blue_name = 'rst-blue';
$rst_blue_x = 172px;
$rst_blue_y = 108px;
$rst_blue_offset_x = -172px;
$rst_blue_offset_y = -108px;
$rst_blue_width = 50px;
$rst_blue_height = 42px;
$rst_blue_total_width = 222px;
$rst_blue_total_height = 206px;
$rst_blue_image = '../images/sprites/retina.png';
$rst_blue = 172px 108px -172px -108px 50px 42px 222px 206px '../images/sprites/retina.png' 'rst-blue';
$rst_name = 'rst';
$rst_x = 0px;
$rst_y = 164px;
$rst_offset_x = 0px;
$rst_offset_y = -164px;
$rst_width = 50px;
$rst_height = 42px;
$rst_total_width = 222px;
$rst_total_height = 206px;
$rst_image = '../images/sprites/retina.png';
$rst = 0px 164px 0px -164px 50px 42px 222px 206px '../images/sprites/retina.png' 'rst';
$volume_name = 'volume';
$volume_x = 60px;
$volume_y = 136px;
$volume_offset_x = -60px;
$volume_offset_y = -136px;
$volume_width = 24px;
$volume_height = 18px;
$volume_total_width = 222px;
$volume_total_height = 206px;
$volume_image = '../images/sprites/retina.png';
$volume = 60px 136px -60px -136px 24px 18px 222px 206px '../images/sprites/retina.png' 'volume';
$check_2x_name = 'check@2x'
$check_2x_x = 184px
$check_2x_y = 272px
$check_2x_offset_x = -184px
$check_2x_offset_y = -272px
$check_2x_width = 32px
$check_2x_height = 28px
$check_2x_total_width = 444px
$check_2x_total_height = 412px
$check_2x_image = '../images/sprites/retina@2x.png'
$check_2x = 184px 272px -184px -272px 32px 28px 444px 412px '../images/sprites/retina@2x.png' 'check@2x'
$infos_area_2x_name = 'infos-area@2x'
$infos_area_2x_x = 176px
$infos_area_2x_y = 0px
$infos_area_2x_offset_x = -176px
$infos_area_2x_offset_y = 0px
$infos_area_2x_width = 152px
$infos_area_2x_height = 124px
$infos_area_2x_total_width = 444px
$infos_area_2x_total_height = 412px
$infos_area_2x_image = '../images/sprites/retina@2x.png'
$infos_area_2x = 176px 0px -176px 0px 152px 124px 444px 412px '../images/sprites/retina@2x.png' 'infos-area@2x'
$infos_composition_2x_name = 'infos-composition@2x'
$infos_composition_2x_x = 0px
$infos_composition_2x_y = 164px
$infos_composition_2x_offset_x = 0px
$infos_composition_2x_offset_y = -164px
$infos_composition_2x_width = 104px
$infos_composition_2x_height = 148px
$infos_composition_2x_total_width = 444px
$infos_composition_2x_total_height = 412px
$infos_composition_2x_image = '../images/sprites/retina@2x.png'
$infos_composition_2x = 0px 164px 0px -164px 104px 148px 444px 412px '../images/sprites/retina@2x.png' 'infos-composition@2x'
$infos_form_2x_name = 'infos-form@2x'
$infos_form_2x_x = 0px
$infos_form_2x_y = 0px
$infos_form_2x_offset_x = 0px
$infos_form_2x_offset_y = 0px
$infos_form_2x_width = 160px
$infos_form_2x_height = 148px
$infos_form_2x_total_width = 444px
$infos_form_2x_total_height = 412px
$infos_form_2x_image = '../images/sprites/retina@2x.png'
$infos_form_2x = 0px 0px 0px 0px 160px 148px 444px 412px '../images/sprites/retina@2x.png' 'infos-form@2x'
$logo_middle_2x_name = 'logo-middle@2x'
$logo_middle_2x_x = 344px
$logo_middle_2x_y = 108px
$logo_middle_2x_offset_x = -344px
$logo_middle_2x_offset_y = -108px
$logo_middle_2x_width = 92px
$logo_middle_2x_height = 92px
$logo_middle_2x_total_width = 444px
$logo_middle_2x_total_height = 412px
$logo_middle_2x_image = '../images/sprites/retina@2x.png'
$logo_middle_2x = 344px 108px -344px -108px 92px 92px 444px 412px '../images/sprites/retina@2x.png' 'logo-middle@2x'
$logo_small_2x_name = 'logo-small@2x'
$logo_small_2x_x = 212px
$logo_small_2x_y = 328px
$logo_small_2x_offset_x = -212px
$logo_small_2x_offset_y = -328px
$logo_small_2x_width = 72px
$logo_small_2x_height = 76px
$logo_small_2x_total_width = 444px
$logo_small_2x_total_height = 412px
$logo_small_2x_image = '../images/sprites/retina@2x.png'
$logo_small_2x = 212px 328px -212px -328px 72px 76px 444px 412px '../images/sprites/retina@2x.png' 'logo-small@2x'
$logo_2x_name = 'logo@2x'
$logo_2x_x = 236px
$logo_2x_y = 164px
$logo_2x_offset_x = -236px
$logo_2x_offset_y = -164px
$logo_2x_width = 88px
$logo_2x_height = 92px
$logo_2x_total_width = 444px
$logo_2x_total_height = 412px
$logo_2x_image = '../images/sprites/retina@2x.png'
$logo_2x = 236px 164px -236px -164px 88px 92px 444px 412px '../images/sprites/retina@2x.png' 'logo@2x'
$natural_blue_2x_name = 'natural-blue@2x'
$natural_blue_2x_x = 116px
$natural_blue_2x_y = 328px
$natural_blue_2x_offset_x = -116px
$natural_blue_2x_offset_y = -328px
$natural_blue_2x_width = 80px
$natural_blue_2x_height = 84px
$natural_blue_2x_total_width = 444px
$natural_blue_2x_total_height = 412px
$natural_blue_2x_image = '../images/sprites/retina@2x.png'
$natural_blue_2x = 116px 328px -116px -328px 80px 84px 444px 412px '../images/sprites/retina@2x.png' 'natural-blue@2x'
$ramn_blue_2x_name = 'ramn-blue@2x'
$ramn_blue_2x_x = 344px
$ramn_blue_2x_y = 0px
$ramn_blue_2x_offset_x = -344px
$ramn_blue_2x_offset_y = 0px
$ramn_blue_2x_width = 100px
$ramn_blue_2x_height = 92px
$ramn_blue_2x_total_width = 444px
$ramn_blue_2x_total_height = 412px
$ramn_blue_2x_image = '../images/sprites/retina@2x.png'
$ramn_blue_2x = 344px 0px -344px 0px 100px 92px 444px 412px '../images/sprites/retina@2x.png' 'ramn-blue@2x'
$ramn_2x_name = 'ramn@2x'
$ramn_2x_x = 120px
$ramn_2x_y = 164px
$ramn_2x_offset_x = -120px
$ramn_2x_offset_y = -164px
$ramn_2x_width = 100px
$ramn_2x_height = 92px
$ramn_2x_total_width = 444px
$ramn_2x_total_height = 412px
$ramn_2x_image = '../images/sprites/retina@2x.png'
$ramn_2x = 120px 164px -120px -164px 100px 92px 444px 412px '../images/sprites/retina@2x.png' 'ramn@2x'
$rst_blue_2x_name = 'rst-blue@2x'
$rst_blue_2x_x = 344px
$rst_blue_2x_y = 216px
$rst_blue_2x_offset_x = -344px
$rst_blue_2x_offset_y = -216px
$rst_blue_2x_width = 100px
$rst_blue_2x_height = 84px
$rst_blue_2x_total_width = 444px
$rst_blue_2x_total_height = 412px
$rst_blue_2x_image = '../images/sprites/retina@2x.png'
$rst_blue_2x = 344px 216px -344px -216px 100px 84px 444px 412px '../images/sprites/retina@2x.png' 'rst-blue@2x'
$rst_2x_name = 'rst@2x'
$rst_2x_x = 0px
$rst_2x_y = 328px
$rst_2x_offset_x = 0px
$rst_2x_offset_y = -328px
$rst_2x_width = 100px
$rst_2x_height = 84px
$rst_2x_total_width = 444px
$rst_2x_total_height = 412px
$rst_2x_image = '../images/sprites/retina@2x.png'
$rst_2x = 0px 328px 0px -328px 100px 84px 444px 412px '../images/sprites/retina@2x.png' 'rst@2x'
$volume_2x_name = 'volume@2x'
$volume_2x_x = 120px
$volume_2x_y = 272px
$volume_2x_offset_x = -120px
$volume_2x_offset_y = -272px
$volume_2x_width = 48px
$volume_2x_height = 36px
$volume_2x_total_width = 444px
$volume_2x_total_height = 412px
$volume_2x_image = '../images/sprites/retina@2x.png'
$volume_2x = 120px 272px -120px -272px 48px 36px 444px 412px '../images/sprites/retina@2x.png' 'volume@2x'
$sp_retina_width = 222px;
$sp_retina_height = 206px;
$sp_retina_image = '../images/sprites/retina.png';
$sp_retina_sprites = $check $infos_area $infos_composition $infos_form $logo_middle $logo_small $logo $natural_blue $ramn_blue $ramn $rst_blue $rst $volume;
$sp_retina = 222px 206px '../images/sprites/retina.png' $sp_retina_sprites;
$retina_spritesheet_width = 444px
$retina_spritesheet_height = 412px
$retina_spritesheet_image = '../images/sprites/retina@2x.png'
$retina_spritesheet_sprites = $check_2x $infos_area_2x $infos_composition_2x $infos_form_2x $logo_middle_2x $logo_small_2x $logo_2x $natural_blue_2x $ramn_blue_2x $ramn_2x $rst_blue_2x $rst_2x $volume_2x
$retina_spritesheet = 444px 412px '../images/sprites/retina@2x.png' $retina_spritesheet_sprites

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.

// The list formatted variables are intended for mixins like `retinaSprite` and `retinaSprites`.

$check_group_name = 'check'
$check_group = 'check' $check $check_2x
$infos_area_group_name = 'infos-area'
$infos_area_group = 'infos-area' $infos_area $infos_area_2x
$infos_composition_group_name = 'infos-composition'
$infos_composition_group = 'infos-composition' $infos_composition $infos_composition_2x
$infos_form_group_name = 'infos-form'
$infos_form_group = 'infos-form' $infos_form $infos_form_2x
$logo_middle_group_name = 'logo-middle'
$logo_middle_group = 'logo-middle' $logo_middle $logo_middle_2x
$logo_small_group_name = 'logo-small'
$logo_small_group = 'logo-small' $logo_small $logo_small_2x
$logo_group_name = 'logo'
$logo_group = 'logo' $logo $logo_2x
$natural_blue_group_name = 'natural-blue'
$natural_blue_group = 'natural-blue' $natural_blue $natural_blue_2x
$ramn_blue_group_name = 'ramn-blue'
$ramn_blue_group = 'ramn-blue' $ramn_blue $ramn_blue_2x
$ramn_group_name = 'ramn'
$ramn_group = 'ramn' $ramn $ramn_2x
$rst_blue_group_name = 'rst-blue'
$rst_blue_group = 'rst-blue' $rst_blue $rst_blue_2x
$rst_group_name = 'rst'
$rst_group = 'rst' $rst $rst_2x
$volume_group_name = 'volume'
$volume_group = 'volume' $volume $volume_2x
$retina_groups = $check_group $infos_area_group $infos_composition_group $infos_form_group $logo_middle_group $logo_small_group $logo_group $natural_blue_group $ramn_blue_group $ramn_group $rst_blue_group $rst_group $volume_group

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  spriteWidth($icon_home)
}

.icon-email {
  sprite($icon_email)
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
spriteWidth($sprite) {
  width: $sprite[4];
}

spriteHeight($sprite) {
  height: $sprite[5];
}

spritePosition($sprite) {
  background-position: $sprite[2] $sprite[3];
}

spriteImage($sprite) {
  background-image: url($sprite[8]);
}

sprite($sprite) {
  spriteImage($sprite)
  spritePosition($sprite)
  spriteWidth($sprite)
  spriteHeight($sprite)
}


// The `retinaSprite` mixin sets up rules and a media query for a sprite/retina sprite.
// It should be used with a "retina group" variable.
//
// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
//
// $icon_home_group = 'icon-home' $icon_home $icon_home_2x;
//
// .icon-home {
//     retinaSprite($icon_home_group)
// }


spriteBackgroundSize($sprite) {
	background-size $sprite[6] $sprite[7]
}

retinaSprite($retina_group) {
	$normal_sprite = $retina_group[1]
	$retina_sprite = $retina_group[2]
	sprite($normal_sprite)

	@media (-webkit-min-device-pixel-ratio: 2),
				 (min-resolution: 192dpi) {
		spriteImage($retina_sprite)
		spriteBackgroundSize($normal_sprite)
	}
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of Stylus

This must be run when you have at least 2 sprites.
  If run with a single sprite, then there will be reference errors.

sprites($spritesheet_sprites);
*/
sprites($sprites) {
  for $sprite in $sprites {
    $sprite_name = $sprite[9];
    .{$sprite_name} {
      sprite($sprite);
    }
  }
}

// The `retinaSprites` mixin generates a CSS rule and media query for retina groups
// This yields the same output as CSS retina template but can be overridden in Stylus
//
// retinaSprites($retina_groups)

retinaSprites($retina_groups) {
	for $retina_group in $retina_groups {
		$sprite_name = $retina_group[0];
		.{$sprite_name} {
			retinaSprite $retina_group
		}
	}
}
