.audiojs
	position relative
	width 302px
	height 40px
	padding 0 50px 0 0
	background-color #96c55a
	border-radius 20px
	user-select none

	&:after
		position absolute
		content ""
		top 0
		right 13px
		bottom 0
		margin auto
		retinaSprite $volume_group

	.play-pause
		position relative
		display inline-block
		vertical-align top
		width 38px
		height 30px
		margin 5px 3px

	.play
		position: absolute
		top 7px
		left 19px
		border-style solid
		border-color transparent transparent transparent #fff
		border-width 7px 0 7px 9px
		cursor pointer

	.pause
		position absolute
		display none
		width 10px
		height 11px
		top 9px
		left 19px
		cursor pointer

		&:before
		&:after
			position absolute
			content ""
			width 3px
			height 100%
			top 0
			background-color #fff

		&:before
			left 0

		&:after
			right 0


	.scrubber
		position relative
		display inline-block
		vertical-align top
		width 200px
		height 3px
		margin-top 18px
		background-color #d8d8d8
		cursor pointer

	.progress
		position absolute
		height 100%
		top 0
		left 0
		background-color #fff

	.loaded
		position absolute
		height 100%
		top 0
		left 0
		background-color rgba(255,255,255, .3)

	.time
	.error-message
		display none

.playing

	.play
		display none

	.pause
		display block
