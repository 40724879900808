.s-doctors
	padding-top 44px
	padding-bottom 44px
	+below($site-width)
		min-width 990px

	&__items
		display table
		width 100%

	&__item
		display table-cell
		vertical-align top
		width 50%

		&_left
			padding-right 24px

		&_right
			padding-left 24px

	&__button-wrapper
		margin-top 45px
		text-align center
