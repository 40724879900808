.s-infos-items
	padding-top 60px
	padding-bottom 60px
	background-color #96c55a
	border-top 9px solid #f4fad6
	border-bottom 9px solid #f4fad6

	&__items
		text-align center

	&__item
		display inline-block
		vertical-align top
		width 280px
		margin 0 20px
		text-align center
		+below($site-width)
			margin 0 12px

		&-title
			margin-bottom 20px
			font-size 22px
			font-weight 500
			line-height 22px
			color #ebf5b2

		&-icon
			margin 0 auto 20px

			&_form
				retinaSprite $infos_form_group

			&_composition
				retinaSprite $infos_composition_group

			&_area
				retinaSprite $infos_area_group

		&-text
			font-size 18px
			font-weight 300
			line-height 22px
			color #293604

