.order
	position relative
	padding 35px 44px 35px 54px
	border 10px solid #96c55a
	border-radius 20px

	&:before
	&:after
		position absolute
		content ""

	&:before
		top -50px
		left -32px
		sprite $sheet1

	&:after
		top 130px
		right -66px
		sprite $sheet2
		+below($site-width-full)
			display none

	&__title
		margin-bottom 32px
		padding-left 224px
		font-size 36px
		font-weight 500
		line-height 38px
		color #e01e4c

	&__cols
		display table
		width 100%

	&__col
		display table-cell
		vertical-align bottom
		width 50%

		&_left
			text-align left

		&_right
			text-align right

	&__info
		display inline-block
		width 235px

	&__price
		padding-bottom 35px
		font-family $lobster
		text-align right
		border-bottom 2px solid #ebf5b2

		&-label
			margin-bottom 6px
			font-size 40px
			line-height 42px
			text-align left
			color #000

		&-value-wrapper
			position relative
			display inline-block
			vertical-align bottom

		&-value
			margin-right 3px
			font-size 56px
			line-height 56px
			color $pink

		&-currency
			font-size 38px
			color $pink

		&-old
			position absolute
			top -8px
			right 0
			font-size 26px
			line-height 26px
			color #a8a8a8

			&:before
				position absolute
				content ""
				width 120%
				height 2px
				top 0
				bottom 0
				left -10%
				margin auto
				background-color $pink
				transform rotate(-18deg)

		&-more
			position absolute
			font-family $roboto
			font-size 17px
			font-weight 300
			line-height 19px
			color #a8a8a8

	&__timeout
		width 255px
		margin-left -10px
		margin-top 16px

	&__form
		display inline-block
		text-align left

		&-input-wrapper
			width 271px
			margin 0 auto 11px

		&-submit-wrapper
			margin-top 25px
			text-align center

	&__logo
		position absolute
		top 0
		right 67px

	&__package
		position absolute
		top 100px
		left 365px
		sprite $package_middle
